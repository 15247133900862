import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useAuth0 } from '@auth0/auth0-react';
import PaymentButton from '../components/PaymentButton';
import MenuItem from '@mui/material/MenuItem';

const frontendMode = process.env.REACT_APP_FRONTEND_MODE || "chem";
const paymentApiUrl = process.env.REACT_APP_PAYMENT_API_URL;

const BillingForm = () => {
    const { user, isAuthenticated } = useAuth0();
    const [billingInfo, setBillingInfo] = useState({
        billing_name: '',
        country: 'Magyarország',
        postal_code: '',
        city: '',
        address: '',
        vat_number: '',
        plan: (frontendMode === "bio" || frontendMode === "szobeli") ? "exercises_only" : "",
    });
    const [loading, setLoading] = useState(false);
    const [hasAccess, setHasAccess] = useState(false);
    const [accessExpires, setAccessExpires] = useState(null);

    // Prevent re-render loops: Set plan only once in bio mode
    useEffect(() => {
        if ((frontendMode === "bio" || frontendMode === "szobeli") && billingInfo.plan !== "exercises_only") {
          setBillingInfo((prev) => ({ ...prev, plan: "exercises_only" }));
        }
    }, [billingInfo.plan]);

    useEffect(() => {
        const checkAccess = async () => {
            try {
                const response = await fetch(`${paymentApiUrl}/check-access?schema=${frontendMode}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email: user.email }),
                });
                const data = await response.json();
                setHasAccess(data.has_access);
                setAccessExpires(data.access_expires);
            } catch (error) {
                console.error("Error checking access:", error);
                setHasAccess(false);
            }
        };

        if (isAuthenticated && user) {
            checkAccess();
        }
    }, [isAuthenticated, user]);

    // Handle case where user is not authenticated
    if (!isAuthenticated) {
        return (
            <Box sx={{ marginBottom: '70px' }}>
                <Typography mt={10} mb={3}>
                    A feladatgyűjteményhez való hozzáférés megvásárlásához 
                    kérlek regisztrálj vagy jelentkezz be!
                </Typography>
            </Box>
        );
    }

    // Handle case where user has active access
    if (hasAccess) {
        const formattedDate = accessExpires ? new Date(accessExpires).toLocaleDateString('hu-HU') : 'Ismeretlen dátum';
        return (
            <Box sx={{ marginBottom: '70px' }}>
                <Typography mt={10} mb={3}>
                    Jelenleg aktív előfizetéssel rendelkezel, így ez az oldal számodra nem elérhető. <br />
                    A feladatgyűjteményhez eddig férsz hozzá: <b>{formattedDate}</b>
                </Typography>
            </Box>
        );
    }

    // Handle case where user is authenticated but does not have access
    const handleChange = (e) => {
        setBillingInfo({ ...billingInfo, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const response = await fetch(`${paymentApiUrl}/store-billing-info`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: user.sub,
                    ...billingInfo,
                }),
            });
            if (response.ok) {
                console.log('Billing info stored successfully');
            } else {
                console.error('Failed to store billing info');
            }
        } catch (error) {
            console.error('Error storing billing info:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: '90px' }}>
            <Typography variant="h6" gutterBottom marginBottom={2}>
                Kérlek add meg a vásárláshoz szükséges adatokat
            </Typography>
            <TextField
                required
                fullWidth
                id="billing_name"
                label="Számlázási név"
                name="billing_name"
                value={billingInfo.billing_name}
                onChange={handleChange}
                margin="normal"
                sx={{ maxWidth: 400 }}
            />
            <TextField
                required
                fullWidth
                id="country"
                label="Ország"
                name="country"
                value={billingInfo.country}
                onChange={handleChange}
                margin="normal"
                sx={{ maxWidth: 400 }}
            />
            <TextField
                required
                fullWidth
                id="postal_code"
                label="Irányítószám"
                name="postal_code"
                value={billingInfo.postal_code}
                onChange={handleChange}
                margin="normal"
                sx={{ maxWidth: 400 }}
            />
            <TextField
                required
                fullWidth
                id="city"
                label="Település"
                name="city"
                value={billingInfo.city}
                onChange={handleChange}
                margin="normal"
                sx={{ maxWidth: 400 }}
            />
            <TextField
                required
                fullWidth
                id="address"
                label="Cím (közterület, házszám, emelet, ajtó)"
                name="address"
                value={billingInfo.address}
                onChange={handleChange}
                margin="normal"
                sx={{ maxWidth: 400 }}
            />
            <TextField
                fullWidth
                id="vat_number"
                label="Adószám (céges vásárlás esetén)"
                name="vat_number"
                value={billingInfo.vat_number}
                onChange={handleChange}
                margin="normal"
                sx={{ maxWidth: 400 }}
            />
            {!(frontendMode === "bio" || frontendMode === "szobeli") ? (
                <TextField
                    select
                    required
                    fullWidth
                    id="plan"
                    label="Melyik csomagot választod?"
                    name="plan"
                    value={billingInfo.plan || ''}
                    onChange={handleChange}
                    margin="normal"
                    sx={{ maxWidth: 400 }}
                >
                    <MenuItem value="" disabled>
                        Válassz egy opciót
                    </MenuItem>
                    <MenuItem value="exercises_with_videos">
                        Feladatgyűjtemény + Videókurzus (29.990 Ft | 1 éves hozzáférés)
                    </MenuItem>
                    <MenuItem value="exercises_only">
                        Feladatgyűjtemény (6.990 Ft | 1 éves hozzáférés)
                    </MenuItem>
                </TextField>
            ) : null}

            <PaymentButton
                billingInfo={billingInfo}
                setBillingInfo={setBillingInfo}
                setLoading={setLoading}
                loading={loading}
            />
        </Box>
    );
};

export default BillingForm;
