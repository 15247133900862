import React from "react";
import { Typography, Box, CircularProgress } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";

const FailurePage = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <CircularProgress style={{ marginTop: "100px" }} />;
  }

  return (
    <div style={{ margin: "0 auto", maxWidth: "1000px", paddingBottom: "100px" }}>
      <Box mt={10}>
        <Typography variant="h4" align="center">
          <strong>Sikertelen fizetés!</strong>
        </Typography>
        <Typography mt={2} align="center">
          A fizetés során valamilyen probléma lépett fel. Kérlek, próbáld meg később újra.
          <br />
          Ha a probléma továbbra is fennáll, írj nekünk az{" "}
          <a href="mailto:info@kemszam.hu">info@kemszam.hu</a> e-mail címen, és megoldjuk máshogy.
        </Typography>
      </Box>
    </div>
  );
};

export default FailurePage;
