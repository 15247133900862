import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Container } from '@mui/material';
import styles from './Kapcsolat.module.css';

const paymentApiUrl = process.env.REACT_APP_PAYMENT_API_URL;
const frontendMode = process.env.REACT_APP_FRONTEND_MODE || "chem";

const Kapcsolat = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setResponseMessage('');

    try {
      const response = await fetch(`${paymentApiUrl}/kapcsolat-email`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...formData, schema: frontendMode }), // Include schema dynamically
      });
    
      if (response.ok) {
        setResponseMessage('Üzenet sikeresen elküldve!');
        setFormData({ name: '', email: '', subject: '', message: '' }); // Reset form
      } else {
        setResponseMessage('Hiba történt az üzenet elküldése során.');
      }
    } catch (error) {
      setResponseMessage('Hálózati hiba történt az üzenet elküldése során.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Container sx={{ mt: 8, mb: 5, textAlign: 'center' }}>
        <h2 style={{ marginBottom: '40px' }}>Bármilyen kérdés, észrevétel esetén az alábbi elérhetőségeken keressen/keressetek.</h2>

        <Typography mt={5} mb={2} sx={{ maxWidth: '600px', margin: '0 auto' }}>
          Lehet ez egy hiba észrevétele, egyéni ajánlat kérése, vagy egyéb kérdés az oldal működésével, esetleges együttműködéssel kapcsolatban.
        </Typography>

        <Box display="flex" justifyContent="center" alignItems="center" mt={5} mb={0}>
          <span className={styles.icon}>&#x260E;</span>
          <Typography>+36-30-231-4666 (elérhető: h-p 10:00-19:00)</Typography>
        </Box>

        <Box display="flex" justifyContent="center" alignItems="center" mb={5}>
          <span className={styles.icon}>&#x2709;</span>
          {frontendMode === "chem" && (
            <Typography>info@kemszam.hu</Typography>
          )}
          {frontendMode === "bio" || frontendMode === "szobeli" && (
            <Typography>info@lvlmeup.hu</Typography>
          )}
        </Box>

        <Typography variant="h5" mb={1}>Üzenetküldés:</Typography>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{ maxWidth: '600px', margin: '0 auto' }}
          onSubmit={handleSubmit}
        >
          <TextField
            fullWidth
            label="Név"
            name="name"
            variant="outlined"
            margin="normal"
            value={formData.name}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            label="E-mail cím"
            name="email"
            type="email"
            variant="outlined"
            margin="normal"
            value={formData.email}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            label="Tárgy"
            name="subject"
            variant="outlined"
            margin="normal"
            value={formData.subject}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            label="Üzenet"
            name="message"
            variant="outlined"
            multiline
            rows={4}
            margin="normal"
            value={formData.message}
            onChange={handleChange}
          />
          {responseMessage ? (
            <Typography variant="body1" color="success.main" mt={2} mb={10}>
              {responseMessage}
            </Typography>
          ) : (
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 2, mb: 5 }}
              type="submit"
              disabled={loading} // Disable button while loading
            >
              {loading ? 'Küldés...' : 'Küldés'}
            </Button>
          )}
        </Box>
      </Container>
    </div>
  );
};

export default Kapcsolat;
