import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Typography, Box, CircularProgress } from "@mui/material";
import Footer from "../components/Footer";

const SuccessPage = () => {
  const { user, isLoading } = useAuth0();

  useEffect(() => {
    if (user) {
      const urlParams = new URLSearchParams(window.location.search);
      const sessionId = urlParams.get("session_id");
      const upgrade = urlParams.get("upgrade")?.toLowerCase() === "true";

      console.log("Retrieved session ID:", sessionId);
      console.log("Upgrade flag:", upgrade);
      console.log("User Name:", user.name);
    }
  }, [user]);

  if (isLoading) {
    return <CircularProgress style={{ marginTop: "100px" }} />;
  }

  return (
    <div
      style={{ margin: "0 auto", maxWidth: "1000px", paddingBottom: "100px" }}
    >
      <Box mt={10}>
        <Typography variant="h4" align="center">
          <strong>Sikeres fizetés!</strong>
        </Typography>
        <Typography mt={2} align="center">
          Köszönjük, hogy a kemszam.hu-t választottad.
          <br /> Mostantól 365 napig hozzáférsz a feladatokhoz.
        </Typography>
      </Box>
      <Footer />
    </div>
  );
};

export default SuccessPage;
