import './App.css';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Navbar from './components/Navbar';
import NavbarMarketing from './components/NavbarMarketing';
import Footer from './components/Footer';
import Fooldal from './pages/Fooldal';
import Navigator from './pages/Navigator';
import Videos from './pages/Videos';
import Feladatok from './pages/Feladatok';
import Fejezetek from './pages/Fejezetek';
import Statisztikak from './pages/Statisztikak';
import SuccessPage from './pages/SuccessPage';
import FailurePage from './pages/FailurePage';
import KemiaKalkulator from './pages/KemiaKalkulator';
import BillingForm from './components/BillingForm';
import CookieConsent from 'react-cookie-consent';
import SikeresRegisztracio from './pages/SikeresRegisztracio';
import Kapcsolat from './pages/Kapcsolat';
import Csomagok from './pages/Csomagok';
import Rolunk from './pages/Rolunk';
import ScrollToTop from './components/ScrollToTop';
import NotFound from './pages/NotFound';
import Loller from "./pages/Loller";

const frontendMode = process.env.REACT_APP_FRONTEND_MODE || "chem";

document.title =
  frontendMode === "bio"
    ? "BioSzám"
    : frontendMode === "szobeli"
    ? "Szóbeli"
    : "KémSzám.hu";


const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        frontendMode === "bio"
          ? "Digitális biológiai számolási feladatgyűjtemény az érettségihez"
          : frontendMode === "szobeli"
          ? "Lehetséges szóbeli C tételek digitális gyűjteménye"
          : "Digitális emelt kémia számolási feladatgyűjtemény az érettségihez"
      );
    }

const favicon = document.querySelector("link[rel='icon']");
if (favicon) {
  favicon.href =
    frontendMode === "bio"
      ? "/assets/bio_logo_favicon.png"
      : frontendMode === "szobeli"
      ? "/assets/lvlUP_logo_cropped.png"
      : "/assets/kemszam_logo_favicon_ver2.png";
}

function RedirectWrapper() {
  const navigate = useNavigate();

  useEffect(() => {
    if (frontendMode === "szobeli" && window.location.pathname === "/") {
      navigate("/rolunk", { replace: true });
    }
  }, [navigate]);

  return null; // This component does not render anything
}

function App() {
  return (
    <Router>
      <RedirectWrapper />
      <ScrollToTop />
      <div className="App">
        <Routes>
          {/* Marketing Site */}
          <Route
            path="/"
            element={
              <>
                <NavbarMarketing />
                <Fooldal />
              </>
            }
          />
          <Route
            path="/kapcsolat"
            element={
              <>
                <NavbarMarketing />
                <Kapcsolat />
              </>
            }
          />
          <Route
            path="/csomagok"
            element={
              <>
                <NavbarMarketing />
                <Csomagok />
              </>
            }
          />
          <Route
            path="/rolunk"
            element={
              <>
                <NavbarMarketing />
                <Rolunk />
              </>
            }
          />

          <Route
            path="/loller"
            element={
              <>
                <Loller />
              </>
            }
          />

          {/* App Routes under /app */}
          <Route
            path="/app/*"
            element={
              <>
                <Navbar />
                <AppRoutes />
              </>
            }
          />

          {/* Catch-all 404 */}
          <Route
            path="*"
            element={
              <>
                <NavbarMarketing />
                <NotFound />
              </>
            }
          />
        </Routes>

        {/* Global Footer */}
        <Footer />

        {/* Global Cookie Consent Popup */}
        <CookieConsent
          location="bottom"
          buttonText="Elfogadom"
          declineButtonText="Elutasítom"
          enableDeclineButton
          containerClasses="cookie-consent-container"
          onAccept={() => {
            console.log('Cookies accepted!');
          }}
          onDecline={() => {
            console.log('Cookies declined!');
          }}
          style={{ background: '#2B373B' }}
          buttonStyle={{
            color: '#4e503b',
            fontSize: '13px',
            backgroundColor: '#FFD700',
          }}
        >
          Oldalunk sütikben tárol információkat, melyek az oldal technikai működéséhez szükségesek. Kérjük olvasd el az{' '}
          <a href="https://lvlmeup.hu/adat/" target="blank" style={{ color: '#FFD700' }}>
            adatvédelmi tájékoztatónkat
          </a>
          .
        </CookieConsent>
      </div>
    </Router>
  );
}

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Feladatok />} />
      <Route path="navigator" element={<Navigator />} />
      <Route path="navigator/:exerciseId" element={<Navigator />} /> {/* This is exercise number */}
      <Route path="navigator/id/:exerciseDbId" element={<Navigator />} /> {/* This is exercise id */}
      <Route path="feladatok" element={<Feladatok />} />
      <Route path="fejezetek" element={<Fejezetek />} />
      <Route path="fejezetek/:chapterBId" element={<Fejezetek />} />
      <Route path="statisztikak" element={<Statisztikak />} />
      <Route path="videok" element={<Videos />} />
      <Route path="sikeres-fizetes" element={<SuccessPage />} />
      <Route path="sikertelen-fizetes" element={<FailurePage />} />
      <Route path="billing-form" element={<BillingForm />} />
      <Route path="kemiakalkulator" element={<KemiaKalkulator />} />
      <Route path="sikeres-regisztracio" element={<SikeresRegisztracio />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
