import React from 'react';
import { Container, Divider, Box, Button } from '@mui/material';
import StartPaymentButton from '../components/StartPaymentButton';
import './Fooldal.css';
{/*import WarningBanner from '../components/WarningBanner';*/}

const frontendMode = process.env.REACT_APP_FRONTEND_MODE || "chem";

const Fooldal = () => {
  return (
    <div>
      {/*<WarningBanner /> {/* Add the WarningBanner component here */}
      <HeroSection />
      <HowToUseSection />
      <StatisticsSection />
      <JoinUsSection />
      <LvlUpSection />
    </div>
  );
};

const HeroSection = () => {
  return (
    <Container className="section">
      
      {frontendMode === "chem" && (
        <Box className="section-content">
          <div className="content-wrapper" style={{ marginTop: '40px' }}>
            <div className="text">
              <p className="hero-authors" style={{ 
                textAlign: 'left', 
                fontWeight: 'bold', 
                marginBottom: '1px', 
                fontSize: '1.3rem', 
                color: '#6c7a89', 
                lineHeight: '3rem',
                marginLeft: '20px' // Align with the title and other text
              }}>
                VILLÁNYI ATTILA,&nbsp;&nbsp;DR. SZABÓ MÁRK
              </p>
              
              <img 
                src="/assets/logo.png" 
                alt="Kémszám Logo" 
                style={{ 
                  marginLeft: '20px',
                  textAlign: 'left',
                  display: 'block',  // Prevent it from being affected by parent text-align
                  maxWidth: '400px', // Limit the maximum width to 400px
                  width: '80%',     // Allow the logo to scale down responsively
                  height: 'auto'     // Maintain aspect ratio while resizing
                }} 
              />

              <h2 style={{
                textAlign: 'left',
                marginLeft: '20px'
              }}>
                Egy modern feladatgyűjtemény, hogy tényleg ötösöd legyen kémiából
              </h2>
              <p className="hero-paragraph" style={{ fontSize: '1.3rem', lineHeight: '1.7', marginTop: '40px', marginLeft: '20px', marginRight: '50px' }}>
                Lehet hatékonyabban készülni a kémiai számolási példák megoldására? Szerintünk igen. A kémszám.hu modern és kényelmes alternatívát nyújt a hagyományos feladatgyűjteményekkel szemben.
              </p>

              {/*<div className="price-section" style={{ textAlign: 'center', marginTop: '20px' }}>
                <span className="crossed-out-price">9.990 Ft</span>
              </div>
              */}

              {/*<div className="gift-section" style={{ 
                  textAlign: 'center', 
                  marginTop: '10px',
                  marginBottom: '20px', 
                  padding: '10px 15px', 
                  backgroundColor: '#e8f5e9', 
                  color: '#388e3c', 
                  fontWeight: 'bold', 
                  fontSize: '1.15rem', 
                  borderRadius: '4px', 
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                }}>
                
                🎁 Ajándék: kémia érettségi számolások <a href="https://kurzus.lvlmeup.hu/courses/kemia-szamolas" rel="noreferrer" className="email-link" target="_blank">videókurzus</a>
              </div>
              */}

              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px', marginBottom: '40px' }}>
                <StartPaymentButton
                  text={
                    "Megvásárolom (6.990 Ft / év)"
                  }
                />
              </div>
            </div>
            <div className="image hero-image">
              <img src="/assets/mobile_phone.png" alt="Hero" />
            </div>
          </div>
        </Box>
      )}

      {frontendMode === "bio" && (
        <Box className="section-content">
          <div className="content-wrapper" style={{ marginTop: '40px' }}>
            <div className="text">
              <p className="hero-authors" style={{ 
                textAlign: 'left', 
                fontWeight: 'bold', 
                marginBottom: '1px', 
                fontSize: '1.3rem', 
                color: '#6c7a89', 
                lineHeight: '3rem',
                marginLeft: '20px' // Align with the title and other text
              }}>
                MORAVCSIK ANNA,&nbsp;&nbsp;DR. SZABÓ MÁRK
              </p>
              
              <img 
                src="/assets/bio_logo.png" 
                alt="bioszam Logo" 
                style={{ 
                  marginLeft: '20px',
                  textAlign: 'left',
                  display: 'block',  // Prevent it from being affected by parent text-align
                  maxWidth: '400px', // Limit the maximum width to 400px
                  width: '80%',     // Allow the logo to scale down responsively
                  height: 'auto'     // Maintain aspect ratio while resizing
                }} 
              />

              <h2 style={{
                textAlign: 'left',
                marginLeft: '20px'
              }}>
                Egy modern feladatgyűjtemény, hogy simán menjenek a bioszos számolások.
              </h2>
              <p className="hero-paragraph" style={{ fontSize: '1.3rem', lineHeight: '1.7', marginTop: '40px', marginLeft: '20px', marginRight: '50px' }}>
                Lehet hatékonyabban készülni a biológia számolási példák megoldására? Szerintünk igen. 
                A bioszám modern és kényelmes alternatívát nyújt a hagyományos feladatgyűjteményekkel szemben.
              </p>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px', marginBottom: '40px' }}>
                <StartPaymentButton
                  text={
                    "Megvásárolom (4.990 Ft / év)"
                  }
                />
              </div>
            </div>
            <div className="image hero-image">
              <img src="/assets/mobile_phone_bio.png" alt="Hero" />
            </div>
          </div>
        </Box>
      )}  

      <Divider style={{ margin: '0', width: '100%', borderTop: '15px solid #ccc' }} />
    </Container>
  );
};

const HowToUseSection = () => {
  return (
    <Container className="section">
      {frontendMode === "chem" && (
        <Box className="section-content calculator-section" style={{ marginTop: '40px'}}>
          <div className="text-wrapper">
            <h2 className="calculator-title">Interaktív, könnyen használható</h2>
            <div className="text">
              <p>Felejtsd el, hogy könyveket hurcolsz magaddal vagy átláthatatlan PDF-ekben kutakodsz a megoldások után! A kémszám.hu egy interaktív feladatgyűjtemény, ahol bármikor hozzáférhetsz a kémiai számolási feladatokhoz. Minden feladat egy helyen, könnyen kereshető és áttekinthető formában áll rendelkezésedre. A megoldások levezetése pedig mindig csak egy gombnyomásnyira van.</p>
            </div>
          </div>
          <div className="image">
            <iframe
              height="170"
              src="https://www.youtube.com/embed/NpEaa2P7qZI?si=_bexlfIWWor65ewc"
              title="YouTube video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </Box>
      )};
      {frontendMode === "bio" && (
        <Box className="section-content calculator-section" style={{ marginTop: '40px'}}>
          <div className="text-wrapper">
            <h2 className="calculator-title">Interaktív, könnyen használható</h2>
            <div className="text">
              <p>Felejtsd el, hogy könyveket hurcolsz magaddal vagy átláthatatlan PDF-ekben 
                kutakodsz a megoldások után! A bioszám egy interaktív feladatgyűjtemény, 
                ahol bármikor hozzáférhetsz a biológiai számolási feladatokhoz. Minden feladat egy helyen, 
                könnyen kereshető és áttekinthető formában áll rendelkezésedre. A megoldások levezetése 
                pedig mindig csak egy gombnyomásnyira van.</p>
            </div>
          </div>
          <div className="image">
            <iframe
              height="170"
              src="https://www.youtube.com/embed/NpEaa2P7qZI?si=_bexlfIWWor65ewc"
              title="YouTube video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </Box>
      )};
      <Divider style={{ margin: '40px auto', width: '100%', borderTop: '15px solid #ccc' }} />
    </Container>
  );
};

const StatisticsSection = () => {
  return (
    <Container className="section">
      {frontendMode === "chem" && (
        <Box className="section-content two-column reverse">
          <div className="text-wrapper">
            <h2 className="statistics-title">Légy tudatában, hogyan is haladsz</h2>
            <p className="statistics-paragraph">A siker kulcsa a tudatos felkészülés. A kémszám.hu részletes statisztikákat biztosít, hogy nyomon tudd követni fejlődésedet. Megjelölheted a feladatokat, melyeket nem sikerült megoldanod. Ezeket vedd elő újra és újra, amíg biztos nem leszel a tudásodban. Láthatod, mely témakörökben vagy erős és melyekben szükséges még a gyakorlás. Így hatékonyabban készülhetsz fel az emelt szintű kémia érettségire.</p>
          </div>
          <div className="image">
            <img src="/assets/statistics.jpg" alt="Statisztika" />
          </div>
        </Box>
      )};
      {frontendMode === "bio" && (
        <Box className="section-content two-column reverse">
          <div className="text-wrapper">
            <h2 className="statistics-title">Légy tudatában, hogyan is haladsz</h2>
            <p className="statistics-paragraph">
              A siker kulcsa a tudatos felkészülés. 
              A bioszám részletes statisztikákat biztosít, hogy nyomon tudd követni 
              fejlődésedet. Megjelölheted a feladatokat, melyeket nem sikerült megoldanod. 
              Ezeket vedd elő újra és újra, amíg biztos nem leszel a tudásodban. Láthatod, mely 
              témakörökben vagy erős és melyekben szükséges még a gyakorlás. Így hatékonyabban készülhetsz 
              fel az emelt szintű biológia érettségire.</p>
          </div>
          <div className="image">
            <img src="/assets/statistics.jpg" alt="Statisztika" />
          </div>
        </Box>
      )};
      <Divider style={{ margin: '40px auto', width: '100%', borderTop: '15px solid #ccc' }} />
    </Container>
  );
};

const JoinUsSection = () => {
  return (
    <Container className="section">
      {frontendMode === "chem" && (
        <Box className="section-content calculator-section">
          <div className="text-wrapper">
            <h2 className="how-to-use-title">Csatlakozz hozzánk és juss be az egyetemre!</h2>
            <div className="text">
              <p>Akár dolgozatra készülsz, akár az érettségire, a kémszám.hu-val a felkészülés egyszerű és hatékony. Csatlakozz hozzánk, és tapasztald meg, hogyan teheted könnyebbé és eredményesebbé a kémiai számolási feladatok gyakorlását.</p>
              {/*<div className="price-section" style={{ textAlign: 'center', marginTop: '20px' }}>
                <span className="crossed-out-price">9.990 Ft</span>
              </div>
              <div className="gift-section" style={{ 
                  textAlign: 'center', 
                  marginTop: '10px',
                  marginBottom: '20px', 
                  padding: '10px 15px', 
                  backgroundColor: '#e8f5e9', 
                  color: '#388e3c', 
                  fontWeight: 'bold', 
                  fontSize: '1.15rem', 
                  borderRadius: '4px', 
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                }}>
                🎁 Ajándék: kémia érettségi számolások <a href="https://kurzus.lvlmeup.hu/courses/kemia-szamolas" rel="noreferrer" className="email-link" target="_blank">videókurzus</a>
              </div>
              */}
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                <StartPaymentButton
                  text={
                    "Megvásárolom (6.990 Ft / év)"
                  }
                />
              </div>
            </div>
          </div>
          <div className="image">
            <img src="/assets/winner.png" alt="Sikeres" />
          </div>
        </Box>
      )};
      {frontendMode === "bio" && (
        <Box className="section-content calculator-section">
          <div className="text-wrapper">
            <h2 className="how-to-use-title">Csatlakozz hozzánk és juss be az egyetemre!</h2>
            <div className="text">
              <p>Akár dolgozatra készülsz, akár az érettségire, a bioszámmal a felkészülés 
                egyszerű és hatékony. Csatlakozz hozzánk, és tapasztald meg, hogyan teheted könnyebbé 
                és eredményesebbé a biológia számolási feladatok gyakorlását.</p>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                <StartPaymentButton
                  text={
                    "Megvásárolom (4.990 Ft / év)"
                  }
                />
              </div>
            </div>
          </div>
          <div className="image">
            <img src="/assets/winner.png" alt="Sikeres" />
          </div>
        </Box>
      )};
      <Divider style={{ margin: '40px auto', width: '100%', borderTop: '15px solid #ccc' }} />
    </Container>
  );
};

const LvlUpSection = () => {
  return (
    <Container className="section">
      <Box className="section-content two-column reverse" style={{ marginBottom: '100px' }}>
        <div className="text-wrapper">
          <h2 className="statistics-title">Teljes körű biológia és/vagy kémia érettségi felkészítőt keresel?</h2>
          <p className="statistics-paragraph">
            A feladatgyűjteményt a{" "}
            <a
                    href="https://lvlmeup.hu"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "#6cbe47", // Green color
                      fontWeight: "bold", // Bold text
                    }}
                  >
                    lvlmeup.hu
                  </a>{" "}
            csapata készítette. A lvlUP többféle biológia és kémia érettségi felkészítő tanfolyamot is kínál.<br></br>
            Járhatsz klasszikus tantermi vagy online <b>100 órás 
            tanfolyamainkra</b>, vagy felkészülhetsz önállóan, bárhonnan, saját tempódban e-learning anyagainkból.<br></br>
            E-learning rendszerünkben lefedjük a teljes elméleti és számolási anyagot <b>videókkal</b>, a megszerzett tudásodat pedig 
            <b> interaktív tesztekkel</b> ellenőrizheted. Az e-learning kurzusainkhoz heti rendszerességű, <b>tanteremben és online is követhető
            órákat</b> tartunk, ahol feladatmegoldásokkal és a ti kérdéseitekkel, problémáitokkal foglalkozunk.<br></br>
            A <span style={{ fontWeight: "bold", color: "#f435ac" }}>kémszám.hu</span> és a <span style={{ fontWeight: "bold", color: "#f435ac" }}>bioszám</span> feladatgyűjteményt 
            a lvlUP előfizetői ingyenesen használhatják.<br></br><br></br>
            Ha érdekel a dolog, nézz körül{" "}
            <a
              href="https://lvlmeup.hu"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "#6cbe47", // Green color
                fontWeight: "bold", // Bold text
              }}
            >
              weboldalunkon
            </a>
            !
            </p>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => window.open('https://lvlmeup.hu', '_blank')}
              >
                Érdekel
              </Button>
            </div>
        </div>
        <div className="image">
          <img src="/assets/lvlUP_logo.png" alt="lvlUP logo" />
        </div>
      </Box>
    </Container>
  );
};

export default Fooldal;
