import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { useAuth0 } from '@auth0/auth0-react';
import { loadStripe } from '@stripe/stripe-js';

const frontendMode = process.env.REACT_APP_FRONTEND_MODE || "chem";
const schema = frontendMode;
const paymentApiUrl = process.env.REACT_APP_PAYMENT_API_URL;
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const UpgradeButton = () => {
    const { user } = useAuth0();
    const [loading, setLoading] = useState(false); // Internal loading state

    const handleClick = async () => {

        if (!paymentApiUrl) {
            console.error('Payment API URL not found');
            return;
        }

        try {
            setLoading(true);

            // Create checkout session
            const response = await fetch(`${paymentApiUrl}/create-checkout-session?schema=${schema}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: user.email,
                    plan: 'exercises_with_videos', // Upgrade to videos plan
                    upgrade: true, // Indicate this is an upgrade
                }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const session = await response.json();

            // Get Stripe.js instance
            const stripe = await stripePromise;

            // Redirect to Stripe Checkout
            const result = await stripe.redirectToCheckout({
                sessionId: session.id,
            });

            if (result.error) {
                console.error(result.error.message);
                alert(result.error.message);
            }
        } catch (error) {
            console.error('Error creating checkout session:', error);
            alert('Error creating checkout session. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Button
            variant="contained"
            onClick={handleClick}
            disabled={loading}
            sx={{
                minWidth: 'auto',
                marginTop: '20px',
                backgroundColor: '#f435ac',
                '&:hover': {
                    backgroundColor: '#d12f95', // Slightly darker shade for hover effect
                },
            }}
        >
            {loading ? 'Betöltés...' : 'Frissítés Videócsomagra (23.990 Ft)'}
        </Button>
    );
};

export default UpgradeButton;
