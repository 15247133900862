import React, { useState, useEffect, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography, Box, Grid, CircularProgress, Tab, Tabs } from '@mui/material';
import ChapterCard from '../components/ChapterCard';
import CustomCard from '../components/Card';
import StartPaymentButton from '../components/StartPaymentButton';
import UpgradeButton from '../components/UpgradeButton';
import Plyr from 'plyr-react';
import 'plyr-react/plyr.css';
import './Fejezetek.css';

const frontendMode = process.env.REACT_APP_FRONTEND_MODE || "chem";
const schema = frontendMode;

const apiUrl = process.env.REACT_APP_API_URL;
const paymentApiUrl = process.env.REACT_APP_PAYMENT_API_URL;

const priceMapping = {
  bio: "4.990 Ft / év",
  chem: "6.990 Ft / év",
  szobeli: "2.990 Ft / év"
};

const selectedPrice = priceMapping[process.env.REACT_APP_FRONTEND_MODE] || "6.990 Ft / év"; // Default to chem price

const videoBaseUrl = process.env.REACT_APP_VIDEO_BASE_URL;
const basePath = process.env.REACT_APP_BASE_PATH || ""; // Default to an empty string
const chapterBasePath = `${basePath}/fejezetek`; // Append '/fejezetek'

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ minHeight: '400px' }}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const Fejezetek = () => {
  const { isAuthenticated, user } = useAuth0();
  const { chapterBId: paramChapterBId } = useParams();
  const navigate = useNavigate();
  const [chapters, setChapters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedChapter, setSelectedChapter] = useState(null);
  const [exercises, setExercises] = useState([]);
  const [chapterADescription, setChapterADescription] = useState('');
  const [chapterBDescription, setChapterBDescription] = useState('');
  const [videos, setVideos] = useState([]); // State for chapter-specific videos
  const [value, setValue] = useState(0);
  const [hasAccess, setHasAccess] = useState(false);
  const [userPlan, setUserPlan] = useState(null);
  const topLevelTabsRef = useRef(null);

  const loadMathJax = () => {
    if (window.MathJax) {
      window.MathJax.typesetPromise().catch((err) => console.log("MathJax error: ", err));
    }
  };

  const setTopLevelTabsRef = (node) => {
    if (node) {
      topLevelTabsRef.current = node;
      scrollToTabs();
    }
  };

  const scrollToTabs = () => {
    if (topLevelTabsRef.current) {
      const navbarHeight = 130;
      const elementPosition = topLevelTabsRef.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - navbarHeight;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const fetchChapters = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(`${apiUrl}/chapters`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ schema }),
        });
        
        if (!response.ok) throw new Error('Nem sikerült betölteni a fejezeteket.');
        const data = await response.json();
        setChapters(data);

        if (paramChapterBId) {
          const chapter = data.find((ch) => ch.chapterB_id === parseInt(paramChapterBId, 10));
          if (chapter) handleChapterCardClick(chapter, false);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchChapters();
  }, [paramChapterBId]);

  useEffect(() => {
    const checkAccess = async () => {
      if (isAuthenticated && user) {
        try {
          const response = await fetch(`${paymentApiUrl}/check-access?schema=${frontendMode}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email: user.email }), // Include schema dynamically
          });
  
          if (!response.ok) throw new Error("Nem a hozzáférésed ellenőrzése.");
  
          const data = await response.json();
          setHasAccess(data.has_access);
          setUserPlan(data.plan); // Keep the plan fetching behavior from original code
        } catch (error) {
          console.error("Error checking access:", error);
          setHasAccess(false);
          setUserPlan(null);
        }
      }
    };
  
    checkAccess();
  }, [isAuthenticated, user]);
  


  const fetchChapterDescriptions = async (chapterBId) => {
    try {
      const response = await fetch(`${apiUrl}/chapterB/details`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ schema, chapterB_id: chapterBId }),
      });
      if (!response.ok) throw new Error('Nem sikerült betölteni a fejezetek leírását.');
      const data = await response.json();
      setChapterADescription(data.chapterA_description || '');
      setChapterBDescription(data.description || '');
      setTimeout(loadMathJax, 0);
    } catch (error) {
      console.error('Error fetching chapter descriptions:', error);
    }
  };

  const fetchVideosForChapter = async (chapterBId) => {
    try {
      const response = await fetch(`${apiUrl}/videos_by_chapter`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ schema, chapterB_id: chapterBId }), // Include schema
      });

      if (!response.ok) throw new Error("Nem sikerült betölteni a videókat.");

      const videoData = await response.json();

      // Store mapped videos in a variable before setting state
      const formattedVideos = videoData.map((video) => ({
        ...video,
        URL: `${videoBaseUrl}${video.video_link}`, // Ensure correct URL format
      }));

      setVideos(formattedVideos); // Now safely update state

    } catch (error) {
      console.error("Error fetching videos:", error);
      setVideos([]); // Ensure state resets properly on error
    }
  };

  const fetchExercisesForChapter = async (chapterB, responseStatus = 'all') => {
    setLoading(true);
    setError(null);
    try {
      const requestBody = { schema, chapterB_id: chapterB };

      if (responseStatus !== 'all') {
        requestBody.user_id = user.sub;
        requestBody.response_status = responseStatus;
      }

      const response = await fetch(
        responseStatus === 'all'
          ? `${apiUrl}/all_exercises_by_chapter` // ✅ Keep this unchanged
          : `${apiUrl}/chapters/exercises`, // ✅ Change /responses to /chapters/exercises
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) throw new Error('Nem sikerült betölteni a feladatokat.');
      
      const data = await response.json();
      setExercises(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };


  const selectChapter = (chapter) => {
    if (chapter && chapter.chapterB_id) {
      setSelectedChapter(chapter);
      fetchChapterDescriptions(chapter.chapterB_id);
      fetchVideosForChapter(chapter.chapterB_id);
      setValue(0);
      fetchExercisesForChapter(chapter.chapterB_id);
    }
  };

  const handleChapterCardClick = (chapter, shouldNavigate = true) => {
    selectChapter(chapter);
    if (shouldNavigate) navigate(`${chapterBasePath}/${chapter.chapterB_id}`);
  };

  useEffect(() => {
    if (!loading && selectedChapter) scrollToTabs();
  }, [loading, selectedChapter]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);

    if (selectedChapter) {
        if (newValue === (frontendMode === "chem" ? 1 : 0)) {
            // Ensure exercises always reload for "Összes"
            fetchExercisesForChapter(selectedChapter.chapterB_id, 'all');
        } else {
            fetchExercisesForChapter(selectedChapter.chapterB_id, valueToFilter(newValue));
        }
    }
  };


  const valueToFilter = (value) => {
    if (frontendMode === "chem") {
        switch (value) {
            case 1: return 'all'; // Összes
            case 2: return 'nem_ment'; // Nem sikerült
            case 3: return 'sikerult'; // Sikerült
            case 4: return 'meg_nem_probaltam'; // Nem próbáltam
            default: return 'all';
        }
    } else {
        switch (value) {
            case 0: return 'all'; // Összes
            case 1: return 'nem_ment'; // Nem sikerült
            case 2: return 'sikerult'; // Sikerült
            case 3: return 'meg_nem_probaltam'; // Nem próbáltam
            default: return 'all';
        }
    }
  };

  

  const getNoExercisesMessage = () => {
    switch (value) {
      case 2: return 'Nincs nem sikerült feladat.';
      case 3: return 'Nincs sikerült feladat.';
      case 4: return 'Nincs még nem próbált feladat.';
      default: return 'Nincs elérhető feladat.';
    }
  };

  if (!isAuthenticated) {
    return (
      <div>
        <Typography mt={10} mb={3}>
          Hogy lásd milyen fejezetekből áll a számolási példatár és kedvedre böngéssz a feladatok között, kérlek jelentkezz be!
        </Typography>
      </div>
    );
  }

  if (!hasAccess) {
    return (
      <div>
        <Typography mt={10} mb={3}>
          Az elérés lejárt vagy nem elérhető. Kérlek frissítsd az előfizetésed!
        </Typography>
        <StartPaymentButton
          text={`Megvásárolom (${selectedPrice})`}
        />
      </div>
    );
  }

  return (
    <div style={{ margin: '0 auto', maxWidth: '1000px' }}>
      <Box mt={3} mb={2} mr={2} ml={2} paddingBottom={10}>
        <Typography variant="h5" mb={3}>Fejezetek</Typography>
        <Grid container spacing={3}>
          {chapters.length === 0 ? (
            <CircularProgress />
          ) : (
            chapters.map((chapter, index) => (
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4} key={index}>
                <ChapterCard chapter={chapter} onClick={() => handleChapterCardClick(chapter)} />
              </Grid>
            ))
          )}
        </Grid>
        {selectedChapter && (
          <div ref={setTopLevelTabsRef}>
            <Typography variant="h5" mt={7} mb={3}>Feladatok: {selectedChapter.chapterB}</Typography>
            <Box className="tabs-container">
              <Box className="tabs-wrapper">
              <Tabs
                value={value}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                TabIndicatorProps={{ style: { backgroundColor: '#1976d2' } }}
              >
                {frontendMode === "chem" && <Tab label="Elméleti összefoglaló" {...a11yProps(0)} className="tab-root" />}
                <Tab label="Összes" {...a11yProps(frontendMode === "chem" ? 1 : 0)} className="tab-root" />
                <Tab label="Nem sikerült" {...a11yProps(frontendMode === "chem" ? 2 : 1)} className="tab-root" />
                <Tab label="Sikerült" {...a11yProps(frontendMode === "chem" ? 3 : 2)} className="tab-root" />
                <Tab label="Nem próbáltam" {...a11yProps(frontendMode === "chem" ? 4 : 3)} className="tab-root" />
              </Tabs>
              </Box>
            </Box>
            {frontendMode === "chem" && (
              <CustomTabPanel value={value} index={0}>
                <Box textAlign="center" style={{ maxWidth: '1250px', margin: '0 auto' }}>
                  <Typography mt={2} mb={3} align="left" style={{ fontSize: '20px' }}>
                    <strong>Főfejezet - {selectedChapter.chapterA}: </strong>
                  </Typography>
                  <Typography
                    mt={2}
                    align="left"
                    className="mathjax-content"
                    style={{ fontSize: '17px', textAlign: 'left', marginLeft: '10px', marginRight: '10px' }}
                    dangerouslySetInnerHTML={{ __html: chapterADescription }}
                  />
                  <Typography mt={5} mb={3} align="left" style={{ fontSize: '20px' }}>
                    <strong>Alfejezet - {selectedChapter.chapterB}:</strong>
                  </Typography>
                  <Typography
                    mt={2}
                    mb={2}
                    align="left"
                    className="mathjax-content"
                    style={{ fontSize: '17px', textAlign: 'left', marginLeft: '10px', marginRight: '10px' }}
                    dangerouslySetInnerHTML={{ __html: chapterBDescription }}
                  />
                  {userPlan === "exercises_with_videos" ? (
                    videos.map((video) => (
                      <Box key={video.id} sx={{ maxWidth: 600, margin: '0 auto', mb: 3 }}>
                        <Typography variant="body1" align="center" mb={1}>{video.title}:</Typography>
                        <Plyr source={{ type: 'video', sources: [{ src: video.URL }] }} />
                      </Box>
                    ))
                  ) : (
                    <Box textAlign="center">
                      <Typography mt={2} mb={3} align="center">
                        A videók eléréséhez fizess elő a videós csomagra!
                      </Typography>
                      <UpgradeButton />
                    </Box>
                  )}
                </Box>
              </CustomTabPanel>
            )}

            {[frontendMode === "chem" ? 1 : 0, frontendMode === "chem" ? 2 : 1, frontendMode === "chem" ? 3 : 2, frontendMode === "chem" ? 4 : 3].map((index) => (
              <CustomTabPanel value={value} index={index} key={index}>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <>
                    {exercises.length === 0 ? (
                      <Typography>{getNoExercisesMessage()}</Typography>
                    ) : (
                      <Grid container spacing={3}>
                        {exercises.map((exercise) => (
                          <Grid item xs={12} sm={6} md={4} lg={4} xl={4} key={exercise.id}>
                            <CustomCard exercise={exercise} />
                          </Grid>
                        ))}
                      </Grid>
                    )}
                    <Box sx={{ minHeight: '250px' }}></Box>
                  </>
                )}
              </CustomTabPanel>
            ))}
          </div>
        )}
      </Box>
      {error && <Typography color="error">{error}</Typography>}
    </div>
  );
};

export default Fejezetek;
