import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Typography, CircularProgress, Grid, Card, CardContent, Box } from '@mui/material';
import StartPaymentButton from '../components/StartPaymentButton';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'; // Use ResponsiveContainer

const frontendMode = process.env.REACT_APP_FRONTEND_MODE || "chem";
const schema = frontendMode;

const apiUrl = process.env.REACT_APP_API_URL;
const paymentApiUrl = process.env.REACT_APP_PAYMENT_API_URL;

const priceMapping = {
  bio: "4.990 Ft / év",
  chem: "6.990 Ft / év",
  szobeli: "2.990 Ft / év"
};

const selectedPrice = priceMapping[process.env.REACT_APP_FRONTEND_MODE] || "6.990 Ft / év"; // Default to chem price

const Statisztikak = () => {
  const { isAuthenticated, user } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [statistics, setStatistics] = useState(null);
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    const fetchStatistics = async () => {
      if (isAuthenticated && user) {
          try {
              const requestBody = {
                  schema,
                  user_id: user.sub,
              };
  
              const response = await fetch(`${apiUrl}/statistics`, {
                  method: "POST",
                  headers: {
                      "Content-Type": "application/json",
                  },
                  body: JSON.stringify(requestBody),
              });
  
              if (!response.ok) {
                  throw new Error("Nem sikerült betölteni a statisztikákat.");
              }
  
              const data = await response.json();
              setStatistics(data);
          } catch (error) {
              setError(error.message);
          } finally {
              setLoading(false);
          }
      }
    };
  

    fetchStatistics();
  }, [isAuthenticated, user]);

  useEffect(() => {
    const checkAccess = async () => {
      if (isAuthenticated && user) {
        try {
          const response = await fetch(`${paymentApiUrl}/check-access?schema=${frontendMode}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: user.email }),
          });
          const data = await response.json();
          setHasAccess(data.has_access);
        } catch (error) {
          console.error('Error checking access:', error);
          setHasAccess(false);
        }
      }
    };

    checkAccess();
  }, [isAuthenticated, user]);

  if (!isAuthenticated) {
    return (
      <div>
        <Typography mt={10} mb={3}>
          Hogy lásd, hogyan haladsz előre a tananyagban, mely témakörök mennek jól és melyekben kell még több gyakorlatra szert tenned, kérlek jelentkezz be!
        </Typography>
      </div>
    );
  }

  if (!hasAccess) {
    return (
      <div>
        <Typography mt={10} mb={3}>
          Az elérés lejárt vagy nem elérhető. Kérlek frissítsd az előfizetésed!
        </Typography>
        <StartPaymentButton
          text={`Megvásárolom (${selectedPrice})`}
        />
      </div>
    );
  }

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  const dailyExercisesData = statistics.daily_completion_stats || [];

  // Sort chapter statistics by chapterA_id (assumed to be the first element in each entry)
  const sortedChapterStatisticsAll = statistics.chapter_statistics_all.sort((a, b) => a[0] - b[0]);

  return (
    <div style={{ margin: '0 auto', maxWidth: '800px', padding: '20px', paddingLeft: '15px', paddingRight: '35px' }}>
      <Grid container spacing={3} mt={3} paddingBottom={10}>
        <Grid item xs={12}>
          <Card 
            style={{ 
              padding: '10px', 
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', 
              margin: '0 auto',
              width: '100%', 
              maxWidth: '100%', 
              overflow: 'visible', // Ensures the shadow isn't cut off
            }}
          >
            <CardContent>
              <Typography variant="h5" align="center">Megoldott feladatok száma</Typography>
              <Grid container spacing={2} justifyContent="center" mt={4}>
                <Grid item xs={12} sm={4}>
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <Typography variant="h4">{statistics.completed_exercises}</Typography>
                    <Typography variant="body1">Összesen</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <Typography variant="h4">{statistics.solved_last_4_weeks}</Typography>
                    <Typography variant="body1">Elmúlt 30 nap</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <Typography variant="h4">{statistics.solved_last_7_days}</Typography>
                    <Typography variant="body1">Elmúlt 7 nap</Typography>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card style={{ padding: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', height: 'auto', margin: '0 auto', width: '100%', maxWidth: '100%', overflow: 'visible' }}>
            <CardContent>
              <Typography variant="h5" align="center">Aktivitás</Typography>
              <Box display="flex" justifyContent="center" alignItems="center" mt={3} width="100%">
                {dailyExercisesData.length > 0 ? (
                  <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={dailyExercisesData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date"/>
                      <YAxis label={{ value: 'Megoldott feladatok', angle: -90, position: 'Left' }}/>
                      <Tooltip />
                      <Line type="monotone" dataKey="count" stroke="#8884d8" activeDot={{ r: 8 }} />
                    </LineChart>
                  </ResponsiveContainer>
                ) : (
                  <Typography variant="body1">Nincs elérhető adat</Typography>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card 
            style={{ 
              padding: '10px', 
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', 
              margin: '0 auto',
              width: '100%', 
              height: 'auto', 
              maxWidth: '100%', 
              overflow: 'visible',
            }}
          >
            <CardContent>
              <Typography variant="h5" align="center">Előrehaladás és sikerességi arány</Typography>
              <Grid container spacing={2} mt={3}>
                <Grid item xs={12} sm={6}>
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <Gauge
                      value={Math.round((statistics.completed_exercises / statistics.total_exercises) * 100)}
                      valueMax={100}
                      height={150}
                      innerRadius="70%"
                      outerRadius="100%"
                      mb={2}
                      sx={{
                        [`& .${gaugeClasses.valueText}`]: {
                          fontSize: 24,
                          transform: 'translate(0px, 0px)',
                        },
                      }}
                      text={({ value }) => `${value}%`}
                    />
                    <Typography variant="body1" align="center">
                      Teljesített feladatok: {statistics.completed_exercises} / {statistics.total_exercises}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <Gauge
                      value={Math.round((statistics.successful_exercises / statistics.completed_exercises) * 100)}
                      valueMax={100}
                      height={150}
                      innerRadius="70%"
                      outerRadius="100%"
                      mb={2}
                      sx={{
                        [`& .${gaugeClasses.valueText}`]: {
                          fontSize: 24,
                          transform: 'translate(0px, 0px)',
                        },
                        [`& .${gaugeClasses.valueArc}`]: {
                          fill: '#52b202',
                        },
                      }}
                      text={({ value }) => `${value}%`}
                    />
                    <Typography variant="body1" align="center">
                      Sikeres feladatok: {statistics.successful_exercises} / {statistics.completed_exercises}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card 
            style={{ 
              padding: '10px', 
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', 
              margin: '0 auto',
              width: '100%',
              height: 'auto', 
              maxWidth: '100%', 
              overflow: 'visible',
            }}>
            <CardContent>
              <Typography variant="h5" align="center">Fejezetenkénti előrehaladás</Typography>
              <Grid container spacing={3} mt={3}>
                {sortedChapterStatisticsAll && sortedChapterStatisticsAll.map(([chapterId, chapterTitle, totalExercises]) => {
                  const completedExercises = statistics.chapter_statistics?.find(([id]) => id === chapterId)?.[2] || 0;
                  const successfulExercises = statistics.chapter_successful_statistics?.find(([id]) => id === chapterId)?.[2] || 0;
                  const completionRate = totalExercises > 0 ? Math.round((completedExercises / totalExercises) * 100) : 0;
                  const successRate = completedExercises > 0 ? Math.round((successfulExercises / completedExercises) * 100) : 0;

                  return (
                    <Grid container item spacing={2} key={chapterId}>
                      <Grid item xs={6}>
                        <Box display="flex" flexDirection="column" alignItems="center">
                          <Gauge
                            value={completionRate}
                            valueMax={100}
                            height={150}
                            innerRadius="70%"
                            outerRadius="100%"
                            mb={2}
                            sx={{
                              [`& .${gaugeClasses.valueText}`]: {
                                fontSize: 24,
                                transform: 'translate(0px, 0px)',
                              },
                            }}
                            text={({ value }) => `${value}%`}
                          />
                          <Typography variant="body1" align="center">
                            {chapterTitle}: {completedExercises}/{totalExercises}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box display="flex" flexDirection="column" alignItems="center">
                          <Gauge
                            value={successRate}
                            valueMax={100}
                            height={150}
                            innerRadius="70%"
                            outerRadius="100%"
                            mb={2}
                            sx={{
                              [`& .${gaugeClasses.valueText}`]: {
                                fontSize: 24,
                                transform: 'translate(0px, 0px)',
                              },
                              [`& .${gaugeClasses.valueArc}`]: {
                                fill: '#52b202',
                              },
                            }}
                            text={({ value }) => `${value}%`}
                          />
                          <Typography variant="body1" align="center">
                            Sikeres feladatok: {successfulExercises}/{completedExercises}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Statisztikak;
