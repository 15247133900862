import React from 'react';
import { Container, Box, Typography, Grid, Divider } from '@mui/material';
import styles from './Rolunk.module.css';
import StartPaymentButton from '../components/StartPaymentButton';

const frontendMode = process.env.REACT_APP_FRONTEND_MODE || "chem";

const Rolunk = () => {
  return (
    <Container sx={{ mt: 7, mb: 15 }}>
      
      {frontendMode === "szobeli" && (
        <Box sx={{ mt: 6}}>
            <h1>Mi is ez a példatár?</h1>
            <Divider
              sx={{
                width: '60%',
                height: '1.5px',
                backgroundColor: 'black',
                margin: '10px auto 40px',
                borderRadius: '4px',
              }}
            />
        </Box>
      )}

      {frontendMode === "chem" && (
        <Box sx={{ maxWidth: '900px', margin: '0 auto' }}> {/* Limit the width of the content */}
          <Grid container spacing={4} alignItems="center">
            {/* Text Section */}
            <Grid item xs={12}>
              <Box sx={{ textAlign: 'left' }}>
                <Typography variant="body1" paragraph>
                  Oktatói pályafutásunk előrehaladtával egyre inkább éreztük, hogy a diákoknak szüksége lenne egy újragondolt, kémiai számolásos feladatokat 
                  tartalmazó példatárra.
                  Úgy döntöttünk, hogy ki kéne használni a modern kor adta lehetőségeket és egy olyan digitális feladatgyűjteményt létrehozni, ami
                  több, mint hagyományos társai.
                  Mivel? Íme néhány példa:
                  <ul>
                    <li>Ne kelljen többé kutakodni a megoldások után</li>
                    <li>Meg lehessen jelölni a problémás feladatokat</li>
                    <li>Lássa a diák, hogy mely témakörökkel hogyan áll, hol kell még fejlődnie</li>
                    <li>Legyenek feladatmegoldós videók</li>
                  </ul>
                </Typography>
                <Typography variant="body1">
                  Ha tetszik a fejlesztésünk, ha tudsz azonosulni a példatár szellemiségével, akkor csatlakozz hozzánk, szerezd meg a tudást, ami a sikeres érettségihez szükséges és kerülj be a vágyott egyetemre a&nbsp;
                  <span style={{ fontWeight: "bold", color: "#f435ac" }}>kémszám.hu</span>
                  -val!
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}

      {frontendMode === "szobeli" && (
        <Box sx={{ maxWidth: '900px', margin: '0 auto' }}>
          <Grid container spacing={4} alignItems="center">
            {/* Text Section */}
            <Grid item xs={12}>
              <Box sx={{ textAlign: 'left' }}>
                <Typography variant="body1" paragraph>
                  Oktatói pályafutásom előrehaladtával egyre inkább éreztem, hogy a diákoknak sokszor nem egy hasznos 
                  tételsor, esetleg a kidolgozott feladatok hiányoznak ahhoz, hogy 
                  sikeres érettségit tehessenek. Jó könyvekből, feladatgyűjteményekből van sok (de legalábbis néhány). 
                  Ami szerintem hiányzik, az a rendszer. Ezért hoztam létre a lvlmeup.hu feladatgyűjteményeit, köztük a szobeli.lvlmeup.hu-t. 
                  Ez egy újragondolt, szóbeli c-tételeket tartalmazó példatár. Úgy gondoltam, hogy ki kéne használni a modern kor adta lehetőségeket 
                  és egy olyan digitális feladatgyűjteményt létrehozni, ami
                  több, mint hagyományos társai.
                  Mivel? Íme néhány példa:
                  <ul>
                    <li>Ne kelljen többé kutakodni a megoldások után</li>
                    <li>Meg lehessen jelölni a problémás feladatokat</li>
                    <li>Lássa a diák, hogy mely témakörökkel hogyan áll, hol kell még fejlődnie</li>
                    <li>Könnyedén, akár azonnal lehessen bővíteni (vagy akár javítani)</li>
                  </ul>
                </Typography>
                <Typography variant="body1">
                  Ha tetszik a fejlesztésünk, ha tudsz azonosulni a példatár szellemiségével, akkor csatlakozz hozzánk, szerezd meg a tudást, ami a sikeres érettségihez szükséges és kerülj be a vágyott egyetemre a&nbsp;
                  <a
                          href="https://lvlmeup.hu/"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            color: "#6cbe47", // Green color
                            fontWeight: "bold", // Bold text
                          }}
                        >
                          lvlmeup.hu
                        </a> 
                  -val!
                </Typography>
                <Typography variant="body1">
                  További fejlesztéseink:<br></br>
                  <ul>
                    <li>Biológia számolási példatár:&nbsp;
                      <a
                          href="https://bioszam.lvlmeup.hu/"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            color: "#6cbe47", // Green color
                            fontWeight: "bold", // Bold text
                          }}
                        >
                          bioszam.lvlmeup.hu
                        </a> 
                    </li>
                    <li>Kémia számolási példatár:&nbsp;
                      <a
                          href="https://kemszam.hu"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            color: "#f435ac",
                            fontWeight: "bold",
                          }}
                        >
                          kemszam.hu
                        </a> 
                    </li>
                  </ul>
                </Typography>
              </Box>
            </Grid>
        
            {/* Button Centering Fix */}
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '0px' }}>
                <StartPaymentButton text="Megvásárolom (2.990 Ft / év)" />
              </Box>
            </Grid>
        
          </Grid>
        </Box>
      )}

      <Box sx={{ mt: 6}}>
          <h1>Kik a példatár megálmodói?</h1>
          <Divider
            sx={{
              width: '60%',
              height: '1.5px',
              backgroundColor: 'black',
              margin: '10px auto 40px',
              borderRadius: '4px',
            }}
          />
      </Box>
      
    
      {frontendMode === "chem" && (
        <Box sx={{ maxWidth: '900px', margin: '0 auto' }}>
          <Grid container spacing={4} alignItems="center">
            {/* Text Section */}
            <Grid item xs={12} md={7}>
              <Box sx={{ textAlign: 'left' }}>
                <Typography gutterBottom marginBottom='-20px'>
                  <h1>Villányi Attila</h1>
                </Typography>
                <Typography variant="h6" gutterBottom color="textSecondary" marginBottom='30px'>
                  a kémszám.hu feladatainak szerzője
                </Typography>
                <Typography variant="body1" paragraph>
                  1981-ben végeztem az ELTE Természettudományi Kar biológia-kémia tanári szakán. 1988-ban
                  kezdtem foglalkozni egy olyan példatár megalkotásával, amely lépésről lépésre megtanítja a használóját számítási feladatokat megoldani.
                  Ebből 1990-re megszületett az „Ötösöm lesz kémiából” első kiadása.
                </Typography>
                <Typography variant="body1" paragraph>
                  1981 és 1988 között a budapesti Szilágyi Erzsébet Gimnáziumban dolgoztam, 1990 óta 
                  pedig az ELTE Apáczai Csere János Gimnáziumban tanítok. Az Apáczaiban több száz diákom 
                  került a korosztályi kémiaversenyek döntőjébe, közülük körülbelül harmincan szereztek 
                  érmet a Nemzetközi Kémiai Diákolimpián.
                </Typography>
                <Typography variant="body1" paragraph>
                  2011 óta egy szaúd-arábiai tehetséggondozó alapítvány megkeresésére szaúdi diákoknak is
                  segítek felkészülni a Nemzetközi Kémiai Diákolimpiára.
                </Typography>
                <Typography variant="body1" paragraph>
                  1990 óta tartok felvételi-, majd 2004-től a kétszintű érettségire készítő magántanfolyamokat,
                  1999 óta a Kemavill Bt. keretében (
                    <a
                        href="https://kemavill.hu"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          color: "#c8041c",
                          fontWeight: "bold", // Bold text
                        }}
                      >
                        kemavill.hu
                      </a> 
                    ). Ez alatt a 34 év alatt több mint 1000 diák készült a segítségemmel az egyetemi továbbtanulásra.
                </Typography>
              </Box>
            </Grid>

            {/* Image Section */}
            <Grid item xs={12} md={5}>
              <Box display="flex" justifyContent="center">
                <img
                  src="/assets/villanyi_attila.jpg"
                  alt="Villányi Attila"
                  className={styles['rolunk-image']}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}


      {frontendMode === "bio" && (
        <Box sx={{ maxWidth: '900px', margin: '0 auto' }}>
          <Grid container spacing={4} alignItems="center">
            {/* Text Section */}
            <Grid item xs={12} md={7}>
              <Box sx={{ textAlign: 'left' }}>
                <Typography gutterBottom marginBottom='-20px'>
                  <h1>Moravcsik Anna</h1>
                </Typography>
                <Typography variant="h6" gutterBottom color="textSecondary" marginBottom='30px'>
                  a bioszám feladatainak összeállítója
                </Typography>
                <Typography variant="body1" paragraph>
                  Szentendrén jártam gimnáziumba, ahonnan a sikeres emelt szintű biológia és fizika érettségimnek 
                  köszönhetően felvételt nyertem a SOTE általános orvosi karára. Az itt töltött idő alatt számos 
                  tudományos területet megismertem, és lehetőségem volt belelátni az alapkutatás szerteágazó világába. 
                  Ez az élmény inspirált arra, hogy három év után átjelentkezzek az ELTE biológia szakára, ahol jelenleg az 
                  Etológiai Tanszéken kapcsolódom be a kutatási munkába.
                </Typography>
                <Typography variant="body1" paragraph>
                  Nagyon szeretem az állatokat, van egy mentett kutyám, és egy teknősöm, akikhez időnként csatlakozik 
                  a szüleim boxere. Szeretek búvárkodni, síelni, és nagyjából mindent, ami a természethez kapcsolódik. 
                  Kivéve a pókokat. Az egészségügyben eltöltött éveim biztos alapokat adtak a humánbiológia területén. 
                  Célom, hogy segítsek mindenkinek ezekben a nehéz témakörökben, és bebizonyítsam, hogy nem is olyan bonyolultak, 
                  mint elsőre tűnnek.
                </Typography>
                <Typography variant="body1" paragraph>
                  Kihívásnak tartom a biológia oktatás izgalmassá, érdekessé tételét, hiszen mi lenne érdekesebb az élővilág rejtelmeinél, 
                  saját szervezetünk működésének a megértésénél, az állatok viselkedésének kutatásánál, és még sorolhatnám… A biológia érettségire 
                  való felkészüléshez átfogó, alapos, könnyen áttekinthető, és naprakész tananyagot szeretnénk biztosítani, a legfrissebb tudományos 
                  eredményekre támaszkodva, a sikeres érettségi feltételeinek a biztonságos ismeretével.
                </Typography>
              </Box>
            </Grid>

            {/* Image Section */}
            <Grid item xs={12} md={5}>
              <Box display="flex" justifyContent="center">
                <img
                  src="/assets/anna_moravcsik.jpg"
                  alt="Moravcsik anna"
                  className={styles['rolunk-image']}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}

      {frontendMode === "chem" && (
        <Box sx={{ maxWidth: '900px', margin: '0 auto', mt: 6 }}>
          <Grid container spacing={4} alignItems="center">
            {/* Image Section (Left) */}
            <Grid item xs={12} md={5}>
              <Box display="flex" justifyContent="center">
                <img
                  src="/assets/mark_szabo.jpg"
                  alt="Dr. Szabó Márk"
                  className={styles['rolunk-image']}
                />
              </Box>
            </Grid>

            {/* Text Section (Right) */}
            <Grid item xs={12} md={7}>
              <Box sx={{ textAlign: 'left' }}>
                <Typography gutterBottom marginBottom='-20px'>
                  <h1>Dr. Szabó Márk</h1>
                </Typography>
                <Typography variant="h6" gutterBottom color="textSecondary" marginBottom='30px'>
                  a kémszám.hu fejlesztője
                </Typography>
                <Typography variant="body1" paragraph>
                  Már a gimnázium kezdetétől a kémia szerelmese vagyok, köszönhetően kiváló kémiatanáromnak.  
                </Typography>
                <Typography variant="body1" paragraph>
                  Azóta a kémia sok oldalát volt lehetőségem megismerni: végeztem kutatómunkát, ahol molekulák szerkezetének meghatározásával
                  foglalkoztam (2021-ben szereztem meg a PhD fokozatomat), fejlesztettem katalizátorokat, részt vettem egy kémiai oktatási 
                  szoftver fejlesztésében. Jelenleg a tanítás mellett kémiai informatikával foglalkozom.
                  Így nem csak papíron láttam a kémiát.
                </Typography>
                <Typography variant="body1" paragraph>
                  Szakmai pályám kezdete óta tanítok, 8 éve pedig csoportos formában is (több info a&nbsp;
                    <a
                      href="https://lvlmeup.hu"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "#6cbe47", // Green color
                        fontWeight: "bold", // Bold text
                      }}
                    >
                      lvlmeup.hu
                    </a>-n). 
                    Már több száz diák készült fel ezeken a tanfolyamokon és jutott be az orvosi egyetemek valamelyikére, sok közülük már végzett orvos.
                </Typography>
                <Typography variant="body1" paragraph>
                  A tanítás mellett két nagy sikerű online biológia–kémia csapatversenyt is szerveztünk (
                  <a
                      href="https://lvlmeup.hu/verseny-2023/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "#6cbe47", // Green color
                        fontWeight: "bold", // Bold text
                      }}
                    >
                      TeamUP
                    </a> 
                  &nbsp;verseny), melyeken több száz diák mérhette össze tudását.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}

      {frontendMode === "bio" && (
        <Box sx={{ maxWidth: '900px', margin: '0 auto', mt: 6 }}>
          <Grid container spacing={4} alignItems="center">
            {/* Image Section (Left) */}
            <Grid item xs={12} md={5}>
              <Box display="flex" justifyContent="center">
                <img
                  src="/assets/mark_szabo.jpg"
                  alt="Dr. Szabó Márk"
                  className={styles['rolunk-image']}
                />
              </Box>
            </Grid>

            {/* Text Section (Right) */}
            <Grid item xs={12} md={7}>
              <Box sx={{ textAlign: 'left' }}>
                <Typography gutterBottom marginBottom='-20px'>
                  <h1>Dr. Szabó Márk</h1>
                </Typography>
                <Typography variant="h6" gutterBottom color="textSecondary" marginBottom='30px'>
                  a lvlmeup.hu feladatgyűjteményeinek fejlesztője
                </Typography>
                <Typography variant="body1" paragraph>
                  Már a gimnázium kezdetétől a kémia szerelmese vagyok, azóta a kémia sok oldalát volt lehetőségem megismerni: végeztem kutatómunkát, ahol molekulák szerkezetének meghatározásával
                  foglalkoztam (2021-ben szereztem meg a PhD fokozatomat), fejlesztettem katalizátorokat, részt vettem egy kémiai oktatási 
                  szoftver fejlesztésében. Jelenleg a tanítás mellett kémiai informatikával foglalkozom.
                </Typography>
                <Typography variant="body1" paragraph>
                  2024-ben úgy döntöttem, hogy belekezdek a saját digitális számolási példatáram fejlesztésébe. Majd kiderült, hasznos lenne 
                  egy hasonló gyűjtemény biológiából is, így a kitalált rendszert használva, Annával közösen létrehoztuk a bioszám feladatgyűjteményt.
                </Typography>
                <Typography variant="body1" paragraph>
                  Szakmai pályám kezdete óta tanítok, 8 éve pedig csoportos formában is (több info a&nbsp;
                    <a
                      href="https://lvlmeup.hu"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "#6cbe47", // Green color
                        fontWeight: "bold", // Bold text
                      }}
                    >
                      lvlmeup.hu
                    </a>-n). 
                    Már több száz diák készült fel ezeken a tanfolyamokon és jutott be az orvosi egyetemek valamelyikére, sok közülük már végzett orvos.
                </Typography>
                <Typography variant="body1" paragraph>
                  A tanítás mellett két nagy sikerű online biológia–kémia csapatversenyt is szerveztünk (
                  <a
                      href="https://lvlmeup.hu/verseny-2023/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "#6cbe47", // Green color
                        fontWeight: "bold", // Bold text
                      }}
                    >
                      TeamUP
                    </a> 
                  &nbsp;verseny), melyeken több száz diák mérhette össze tudását.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}

      {frontendMode === "szobeli" && (
        <Box sx={{ maxWidth: '900px', margin: '0 auto', mt: 6 }}>
          <Grid container spacing={4} alignItems="center">
            {/* Image Section (Left) */}
            <Grid item xs={12} md={5}>
              <Box display="flex" justifyContent="center">
                <img
                  src="/assets/mark_szabo.jpg"
                  alt="Dr. Szabó Márk"
                  className={styles['rolunk-image']}
                />
              </Box>
            </Grid>

            {/* Text Section (Right) */}
            <Grid item xs={12} md={7}>
              <Box sx={{ textAlign: 'left' }}>
                <Typography gutterBottom marginBottom='-20px'>
                  <h1>Dr. Szabó Márk</h1>
                </Typography>
                <Typography variant="h6" gutterBottom color="textSecondary" marginBottom='30px'>
                  a lvlmeup.hu feladatgyűjteményeinek fejlesztője
                </Typography>
                <Typography variant="body1" paragraph>
                  Már a gimnázium kezdetétől a kémia szerelmese vagyok, köszönhetően kiváló kémiatanáromnak.  
                </Typography>
                <Typography variant="body1" paragraph>
                  Azóta a kémia sok oldalát volt lehetőségem megismerni: végeztem kutatómunkát, ahol molekulák szerkezetének meghatározásával
                  foglalkoztam (2021-ben szereztem meg a PhD fokozatomat), fejlesztettem katalizátorokat, részt vettem egy kémiai oktatási 
                  szoftver fejlesztésében. Jelenleg a tanítás mellett kémiai informatikával foglalkozom.
                  Így nem csak papíron láttam a kémiát.
                </Typography>
                <Typography variant="body1" paragraph>
                  Szakmai pályám kezdete óta tanítok, 8 éve pedig csoportos formában is (több info a&nbsp;
                    <a
                      href="https://lvlmeup.hu"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "#6cbe47", // Green color
                        fontWeight: "bold", // Bold text
                      }}
                    >
                      lvlmeup.hu
                    </a>-n). 
                    Már több száz diák készült fel ezeken a tanfolyamokon és jutott be az orvosi egyetemek valamelyikére, sok közülük már végzett orvos.
                </Typography>
                <Typography variant="body1" paragraph>
                  A tanítás mellett két nagy sikerű online biológia–kémia csapatversenyt is szerveztünk (
                  <a
                      href="https://lvlmeup.hu/verseny-2023/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "#6cbe47", // Green color
                        fontWeight: "bold", // Bold text
                      }}
                    >
                      TeamUP
                    </a> 
                  &nbsp;verseny), melyeken több száz diák mérhette össze tudását.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}


    </Container>
  );
};

export default Rolunk;
