import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useAuth0 } from "@auth0/auth0-react";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";

const frontendMode = process.env.REACT_APP_FRONTEND_MODE || "chem";
const paymentApiUrl = process.env.REACT_APP_PAYMENT_API_URL;
const stripePromise = loadStripe(
  frontendMode === "bio"
    ? process.env.REACT_APP_STRIPE_PUBLIC_KEY_BIO
    : process.env.REACT_APP_STRIPE_PUBLIC_KEY
);

const PaymentButton = ({ billingInfo, setBillingInfo, setLoading, loading }) => {
  const { user } = useAuth0();

  // Ensure plan is set in "bio" mode
  useEffect(() => {
    if (frontendMode === "bio" && !billingInfo.plan) {
      setBillingInfo((prevState) => ({
        ...prevState,
        plan: "exercises_only",
      }));
    }
  }, [billingInfo.plan, setBillingInfo]);

  const validateBillingInfo = () => {
    const { billing_name, country, postal_code, city, address } = billingInfo;
    return billing_name && country && postal_code && city && address;
  };

  const handleClick = async () => {
    if (!paymentApiUrl) {
      console.error("Payment API URL not found");
      return;
    }

    if (!validateBillingInfo()) {
      alert("Please fill in all required fields.");
      return;
    }

    if (frontendMode === "chem" && !billingInfo.plan) {
      alert("Kérlek válassz egy csomagot!");
      return;
    }

    if (!user.sub) {
      alert("Hiba történt. Kérlek jelentkezz be újra!");
      return;
    }

    const selectedPlan = billingInfo.plan || "exercises_only";
    const stripe = await stripePromise;

    try {
      setLoading(true);

      // Fetch user info from Auth0 via backend
      const { data: userInfo } = await axios.get(`${paymentApiUrl}/auth0/user-info/${user.sub}`);

      // Store billing info before checkout
      await axios.post(`${paymentApiUrl}/store-billing-info?schema=${frontendMode}`, {
        user_id: user.sub,
        billing_name: billingInfo.billing_name,
        country: billingInfo.country,
        city: billingInfo.city,
        postal_code: billingInfo.postal_code,
        address: billingInfo.address,
        vat_number: billingInfo.vat_number,
        plan: selectedPlan,
      });

      // Create Stripe checkout session
      const { data: session } = await axios.post(`${paymentApiUrl}/create-checkout-session?schema=${frontendMode}`, {
        email: user.email,
        user_id: user.sub,
        name: userInfo.name,
        username: userInfo.username,
        plan: selectedPlan,
      });

      const result = await stripe.redirectToCheckout({ sessionId: session.id });

      if (result.error) {
        alert(result.error.message);
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
      alert("Error creating checkout session. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleClick}
      disabled={loading || !validateBillingInfo() || (frontendMode === "chem" && !billingInfo.plan)}
      style={{ minWidth: "auto", marginTop: "20px" }}
    >
      {loading ? "Betöltés..." : "Kártyás fizetés"}
    </Button>
  );
};

export default PaymentButton;