import React, { useEffect, useState } from 'react';
import { Button, Box, Typography } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Link } from 'react-router-dom';
import './ExerciseContent.css';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';

const frontendMode = process.env.REACT_APP_FRONTEND_MODE || "chem";
const schema = frontendMode;

const apiUrl = process.env.REACT_APP_API_URL;

const CopyLinkButton = ({ exerciseId }) => {
    const [copied, setCopied] = useState(false);
  
    const handleCopy = () => {
      const url = `${window.location.origin}/app/navigator/id/${exerciseId}`;
      navigator.clipboard.writeText(url);
      setCopied(true);
      setTimeout(() => setCopied(false), 1500);
    };
  
    if (!exerciseId) return null;
  
    return (
        <Tooltip
            title={
            <span>
                {copied ? "Link kimásolva!" : (
                <>
                    Link másolása:<br />
                    <em>A feladat sorszáma változhat. Ez a link nem fog.</em>
                </>
                )}
            </span>
            }
        >
        <Button
          onClick={handleCopy}
          size="small"
          style={{ minWidth: 'auto', padding: '4px', marginLeft: '8px' }}
        >
          <ContentCopyIcon fontSize="small" />
        </Button>
      </Tooltip>
    );
};
  

const ExerciseContent = ({ exerciseContent, userId }) => {
    const [responseStatus, setResponseStatus] = useState(null);
    const [isSolutionVisible, setIsSolutionVisible] = useState(false);
    const isWideScreen = useMediaQuery('(min-width:1000px)');

    // Function to load MathJax
    const loadMathJax = () => {
        if (window.MathJax) {
            window.MathJax.typesetPromise()
                .catch((err) => console.log("MathJax error: ", err));
        }
    };

    useEffect(() => {
        const fetchResponseStatus = async () => {
            if (exerciseContent && userId) {
                try {
                    const requestBody = {
                        schema,        // Include schema in request
                        user_id: userId,
                        exercise_id: exerciseContent.id,
                    };
    
                    const result = await fetch(`${apiUrl}/user_response_status/`, { // Use POST method
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(requestBody), // Send request body as JSON
                    });
    
                    if (!result.ok) {
                        throw new Error("Nem sikerült a válaszod ellenőrzése");
                    }
    
                    const data = await result.json();
                    setResponseStatus(data.status);
                } catch (error) {
                    console.error("Error:", error);
                }
            }
        };
    
        fetchResponseStatus();
    }, [exerciseContent, userId]);
    

    useEffect(() => {
        // Reset solution visibility when the exercise content changes
        setIsSolutionVisible(false);
    }, [exerciseContent]);

    useEffect(() => {
        loadMathJax();
    }, [exerciseContent, isSolutionVisible]);

    const handleSolutionToggle = () => {
        setIsSolutionVisible(prevState => !prevState);
    };

    const handleResponse = async (response) => {
        const exerciseId = exerciseContent.id;
        try {
            const requestBody = {
                schema,
                user_id: userId,
                exercise_id: exerciseId,
                response: response,
            };
    
            const result = await fetch(`${apiUrl}/user_responses/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestBody),
            });
    
            if (!result.ok) {
                throw new Error("Nem sikerült a válaszod beküldése");
            }
    
            console.log("Response submitted successfully");
            setResponseStatus(response);
        } catch (error) {
            console.error("Error:", error);
        }
    };
    
    

    if (!exerciseContent) {
        return (
            <Typography mt={2}>
                Még nem töltöttél be feladatot. Írd be a feladat sorszámát a fenti keretbe <br />
                vagy böngéssz a feladatok között a FELADATOK menüpontban.
                <br />
                <br />
                A FEJEZETEK menüpontban adott témakörben kereshetsz feladatokat.
            </Typography>
        );
    }

    return (
        <Box>
            <Box textAlign="center" style={{ maxWidth: '1250px', margin: '0 auto' }}>
                {/* Display Feladat */}
                <Box
                    mt={2}
                    mb={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexWrap="wrap"
                    gap={1}
                >
                    <Typography style={{ fontSize: '17px' }}>
                        <strong>{exerciseContent.number}. feladat</strong> (nehézség: {exerciseContent.difficulty})
                    </Typography>

                    <CopyLinkButton exerciseId={exerciseContent?.id} />
                </Box>

                {/* Display Fejezet */}
                {exerciseContent.chapterBTitle && exerciseContent.chapterBId && (
                <Typography
                    align="center"
                    style={{ fontSize: '17px', fontWeight: 'bold', fontSize: '14px', marginBottom: '20px' }}
                >
                    Fejezet:{" "}
                    <Link
                    to={`/app/fejezetek/${exerciseContent.chapterBId}`}
                    style={{ fontWeight: 'normal', color: 'blue', textDecoration: 'underline' }}
                    >
                    {exerciseContent.chapterBTitle}
                    </Link>
                </Typography>
                )}

                {/* Display Exercise Content */}
                <Typography
                    mt={1}
                    ml={isWideScreen ? 30 : 3}
                    mr={isWideScreen ? 30 : 3}
                    align="left"
                    style={{ display: 'inline-block', fontSize: '17px', textAlign: 'left' }}
                    dangerouslySetInnerHTML={{ __html: exerciseContent.exercise }}
                />
            </Box>
            <Box textAlign="center">
                <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    style={{ marginTop: '30px' }}
                    onClick={handleSolutionToggle}
                >
                    {isSolutionVisible ? "Rejtsd el a megoldást" : "Mutasd a megoldást"}
                </Button>
            </Box>
            {isSolutionVisible && (
                <>
                    <Box textAlign="center" style={{ maxWidth: '1250px', margin: '0 auto' }}>
                        <Typography
                            mt={5}
                            align="center"
                            style={{ fontSize: '16px' }}
                        >
                            <strong>Megoldás:</strong>
                        </Typography>
                        <Typography
                            mt={1}
                            ml={isWideScreen ? 30 : 3}
                            mr={isWideScreen ? 30 : 3}
                            align="left"
                            className="mathjax-content"
                            style={{ display: 'inline-block', textAlign: 'left' }}
                            dangerouslySetInnerHTML={{ __html: exerciseContent.solution }}
                        />
                    </Box>
                    <Box textAlign="center" style={{ maxWidth: '1250px', margin: '0 auto' }}>
                        <Typography
                            mt={5}
                            align="center"
                            style={{ fontSize: '16px' }}
                        >
                            <strong>Magyarázat:</strong>
                        </Typography>
                        <Typography
                            mt={1}
                            ml={isWideScreen ? 30 : 3}
                            mr={isWideScreen ? 30 : 3}
                            align="left"
                            className="mathjax-content"
                            style={{ display: 'inline-block', textAlign: 'left'}}
                            dangerouslySetInnerHTML={{ __html: exerciseContent.explanation }}
                        />
                    </Box>
                </>
            )}
            <Box mt={5} textAlign="center">
                {responseStatus !== null && (
                    <Typography style={{ fontSize: '16px' }}>
                        Utolsó válasz: {responseStatus ? <strong>Sikerült</strong> : <strong>Nem sikerült</strong>}
                    </Typography>
                )}
                <Box mt={1} display="flex" alignItems="center" justifyContent="center">
                    {responseStatus !== null && (
                        <Typography style={{ fontSize: '16px', marginRight: '10px' }}>
                            Megváltoztatom a válaszom:
                        </Typography>
                    )}
                    {responseStatus !== true && (
                        <Button
                            variant="outlined"
                            size="small"
                            color="success"
                            style={{ marginRight: '10px' }}
                            onClick={() => handleResponse(true)}
                            endIcon={<CheckCircleIcon style={{ marginLeft: '-5px', marginRight: '-3px', marginBottom: '2px' }} />}
                        >
                            Sikerült!
                        </Button>
                    )}
                    {responseStatus !== false && (
                        <Button
                            variant="outlined"
                            size="small"
                            color="error"
                            onClick={() => handleResponse(false)}
                            endIcon={<CancelIcon style={{ marginLeft: '-6px' , marginRight: '-3px', marginBottom: '2px'}} />}
                        >
                            Nem sikerült
                        </Button>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default ExerciseContent;
